.input-search-show-name {
    background: white;
    width: 100%;
    border-radius: 3px;
    margin-top: 1%;
    height: 37px;
    border: none;
    font-family: inherit;
    text-indent: 10px;
    
}

.input-search-show-name::placeholder {
    color: "gray";
}