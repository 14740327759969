@media screen and (min-width: 601px) {
  .show-details-title {
    font-size: 3rem;
  }
  .show-details-artist-option-button{
    width: 119px;
  }

  .show-details-logo {
    width: 100px;
  }

  .show-details-row {
    width: 450px;
  }
  
}

@media screen and (max-width: 600px) {
  .show-details-title {
    font-size: 28px;
  }
  .show-details-artist-option-button{
    width: 200px;
  }

  .show-details-logo {
    width: 75px;
  }


  .show-details-row {
    width: 100vw;
  }
}

.show-details-container {
  border: 1px solid #444;
  border-radius: 35px;
  background-color: #1b194d;
  margin-top: 50px;
  margin-bottom: 40px;
  overflow: hidden;
}

.show-details-banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25vh;
  border-bottom: 1px solid #444;
  background-color: #000;
  overflow: hidden;
  margin-bottom: 5vh;
}

.show-details-img-container {
  width: 100%;
}

.show-details-banner-img {
  object-fit: cover;
  width: 100%;
  opacity: 0;
}

.show-details-live-on {
color: var(--red-color);
margin-right: 10px;
}

.show-details-title {
  position: absolute;
  text-transform: uppercase;
  margin-top: calc(12.5vh - 3rem);
  cursor: default;
  max-width: 85vw;
}

.show-details-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show-details-info-box {
  display: flex;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #181644;
  padding: 10px 0;
  margin-bottom: -10px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 245px;
}

.show-details-flier-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
  padding-left: 3vh;
  padding-right: 3vh;
}

.show-details-flier {
  max-width: 80vw;
  max-height: 80vw;
  width: 550px;
  height: 550px;
}

.show-details-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.show-details-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.show-details-info-header {
  margin-bottom: 3vh;
  text-align: left;
}

.show-details-category {
  margin: 0;
  font-family: "bodyFont",;
  font-size: 12px;
  color: #66e2ae;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.show-details-value {
  margin: 0;
  font-family: "titleFont";
  font-size: 1rem;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
}

.show-details-description {
  margin-bottom: -10px;
  padding: 0px 10px 0px 10px;
  font-family: "bodyFont";
  font-size: 0.7rem;
  color: #fff;
  text-align: left;
}

.show-details-artist-img {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  object-fit: cover;
  background: rgb(211, 211, 211);
  background: rgb(80, 80, 80);
  border: 1px solid #fff;
  margin-bottom: 15px;
}

.show-details-artist-name {
  display: block;
  padding: none;
  margin: 5px 0 0 0;
}

.shows-socials-container{
  margin-top: 12px;
}

.shows-socials-icon{
  width: 20px;
  height: 18px;
  margin: 0px 8px 0px 15px;
}

.socials-link{
  color: var(--green-color);
}

.socials-link:hover{
  color: var(--red-color);
  text-decoration: none;
}

.show-details-artist-buttons-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.show-details-artist-option-button{
  margin-left: 8px;
  background-color: #1b194dcb;
  border: 1px solid var(--red-color);
  color: var(--red-color);
  border-radius: 10px;
  font-size: 0.9rem;
  padding: 5px 10px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-align: center;
}

.show-details-artist-option-button:hover {
  background-color: var(--red-color);
  border: 1px solid var(--red-color);
  color: #fff;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.sensitive-details{
  background-color: var(--bg-blue);
  border: 1px solid var(--red-color);
  border-radius: 5px;
  padding: 30px 30px 20px 30px;
  /* top, right, bottom, left*/
  margin-bottom: 20px;
}