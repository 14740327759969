.img-editor-container {
  display: flex;
  position: relative;
  flex-direction: column;
}
.img-box-title {
  display: block;
  margin-bottom: 5px;
  font-family: titleFont;
}
.img-box {
  display: flex;
  height: 118px;
  width: 118px;
  border-radius: 50%;
  background-color: white;
  margin-right: 30px;
}
.img-box-filled {
   text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  height: 118px;
  width: 118px;
  border-radius: 50%;
  background-color: #1b194d56;
  border: none;
  margin-bottom: 1rem;
  margin-right: 30px;
}
.img-box-wrapper-child{
    height: 118px;
  width: 118px;
  border-radius: 50%;
  overflow: hidden;
}
.img-box-filled img{
  max-width: 100%;
}

.final-img {
  border: 1px solid #66e2af;
  background-color: black;
  height: 10%;
  opacity: 1;
}
.img-box-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.img-box-button {
  position: absolute;
  top: 70%;
  left: 70%;
  background-color: #ed1650;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "proximaNovaBold", sans-serif;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 11px;
  cursor: pointer;
}

.caution-button:hover {
  background-color: #ed1650 !important;
  color: #fff !important;
  cursor: pointer;
  text-decoration: none;
}
.drag-n-drop-btn-container {
  width: 100%;
  height: 100%;
  display: inherit;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.edit-icon {
  position: absolute;
  top: 70%;
  left: 70%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
}

.upload-btn {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
}
.upload-btn {
  cursor: pointer;
  color: black;
}

.upload-btn p {
  margin: 0 !important;
  font-size: 12px;
  font-family: "proximaNovaBold", sans-serif;
}

.editIcon {
  margin-top: 5px;
  cursor: pointer;
}

.drag-n-drop-btn {
  font-family: bodyFont;
  background-color: transparent;
  text-align: center;
}

.drag-n-drop-btn p {
  color: rgba(27, 25, 77, 1);
}
.file-select {
  display: none;
}
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.795);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  z-index: 5;
}
.overlay-hidden {
  z-index: -5;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.close-btn {
  background-color: #1b194d;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  text-align: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.close-btn:hover {
  color: #ed1650;
  transition: all 0.15s ease-in-out;
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-decoration: none;
}

.img-modal {
  position: fixed;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  top: 5vh;
  overflow-y: scroll;
  left: 20vw;
  width: 60vw;
  height: 90vh;
  border: 1px solid #fff;
  background-color: #1b194d;
  /* background: linear-gradient(
    transparent,
    #1b194dda,
    #1b194d,
    #1b194d,
    #1b194d
  ); */
  opacity: 1;
  transition: all 0.3s ease-out;
}

.img-modal-hidden {
  position: fixed;
  opacity: 0;
  z-index: -5;
  top: 100vh;
  transition: all 0.3s ease-in;
}

.img-modal-title {
  font-family: bodyFont;
  pointer-events: none;
  color: #ccc;
  letter-spacing: 5px;
  font-size: 0.8rem;
}

.change-img-button {
  display: flex;
  align-items: flex-end;
}

.change-img-button:last-child {
  padding: 5px 25px;
}

.img-ctrl-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5px;
  justify-content: center;
  width: 33vw;
}

.slider {
  width: 95%;
  cursor: grab;
}

.slider:active {
  cursor: grabbing;
}

.slider-title {
  font-family: bodyFont;
  color: #888;
  font-size: 0.6rem;
  letter-spacing: 3px;
  margin-bottom: -5px;
}

.img-modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  width: 400px;
}

.img-modal-button {
  background-color: #1b194d;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: inherit;
  font-family: bodyFont;
  border-radius: 25px;
  padding: 5px 25px;
  color: #ddd;
  font-size: 0.9rem;
  transition: all 0.15s ease-in-out;
}

.img-modal-button:hover {
  background-color: #66e2af;
  color: #1b194d;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  text-decoration: none;
}

/* Slider Styles */

input[type="range"] {
  height: 5px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 14px;
  background-color: rgb(212, 212, 212);
  border-radius: 100px;
}

input[type="range"]::-webkit-slider-thumb {
  border: 1px solid #0f0e2b;
  height: 20px;
  width: 40px;
  border-radius: 25px;
  background: #66e2af;
  -webkit-appearance: none;
  margin-top: -3.5px;
  transition: all 0.15s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:active {
  background: #15133a;
  border: 1px solid #66e2af;
  transition: all 0.15s ease-in-out;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 14px;
  background-color: rgb(212, 212, 212);
  border-radius: 100px;
}

input[type="range"]::-moz-range-thumb {
  border: 1px solid #0f0e2b;
  height: 20px;
  width: 40px;
  border-radius: 25px;
  background: #66e2af;
  -webkit-appearance: none;
  margin-top: -3.5px;
  transition: all 0.15s ease-in-out;
}

input[type="range"]::-moz-range-thumb:active {
  background: #15133a;
  border: 1px solid #66e2af;
  transition: all 0.15s ease-in-out;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 14px;
  background-color: rgb(212, 212, 212);
  border-radius: 100px;
}

input[type="range"]::-ms-thumb {
  border: 1px solid #0f0e2b;
  height: 20px;
  width: 40px;
  border-radius: 25px;
  background: #66e2af;
  -webkit-appearance: none;
  margin-top: -3.5px;
  transition: all 0.15s ease-in-out;
}

input[type="range"]::-ms-thumb:active {
  background: #15133a;
  border: 1px solid #66e2af;
  transition: all 0.15s ease-in-out;
}
