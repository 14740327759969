.confirmation-container {
  position: absolute;
  width: 479px;
  height: 530px;
  top: 152px;
  background: rgba(27, 25, 77, 0.65);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmation-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.confirmation-header p {
  font-family: "titleFont", sans-serif;
  font-size: 16px;
}

.confirmation-form {
  display: flex;
  flex-direction: column;
  padding: 0px 94px 0px 94px;
  text-align: center;
}

.code-form {
  display: flex;
  flex-direction: row;
  justify-content: "center";
  padding: 36px 143px 36px 94px;
}

.code-form input {
  width: 40px;
  height: 40px;
  border: 1.5px solid #b6b6b6;
  border-radius: 2px;
  outline: none;
  text-align: center;
  padding-top: 7px;
}

.code-form input:focus {
  outline: none;
  border: 1.5px solid #b6b6b6;
  background-color: white;
}

#code-1,
#code-2,
#code-3,
#code-4,
#code-5 {
  margin-right: 15px;
}

button {
  width: 292px;
  height: 34px;
  color: white;
  border: none;
  margin-top: 50px;
  font-family: "titleFont", sans-serif;
  font-size: 13px;
}

button p {
  margin: 0px;
}

.resend-code {
  margin-top: 36px;
  margin-bottom: 0px;
  font-size: 12px;
}

.resend-code-clickable {
  text-decoration: underline;
  cursor: pointer;
}

.code-form-description {
  padding: 0px 94px 0px 94px;
  font-size: 13px;
  font-family: "signInFont", sans-serif;
}

.confirmation-error-text {
  font-size: 12px;
  font-family: "signInFont", sans-serif;
  padding: 0px 94px 0px 94px;
  margin-bottom: 0px;
}

.confirmation-error-text span {
  margin-right: 9px;
}
