.list-group a {
  margin-top: 10px;
}
.artist-profile-container {
  border: 1px solid #444;
  border-radius: 35px;
  background-color: #1b194d;
  margin-top: 50px;
  margin-bottom: 40px;
  overflow: hidden;
}
.artist-profile-banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25vh;
  border-bottom: 1px solid #444;
  background-color: #000;
}
.artist-profile-banner-img {
  object-fit: cover;
  width: 100%;
  opacity: 0.15;
}
.artist-img-and-name-box {
  display: flex;
  margin: -12.5vh 30px 0 30px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}
.col-lg-6 {
  display: flex;
  justify-content: flex-end;
}
.artist-profile-img {
  max-height: 25vh;
  max-width: 25vh;
  width: 70vw;
  height: 70vw;
  border-radius: 100%;
  object-fit: cover;
  background: rgb(211, 211, 211);
  background: rgb(80, 80, 80);
  border: 1px solid #fff;
}
.artist-profile-name {
  display: block;
  padding: none;
  margin: 5px 0 0 0;
}
.artist-profile-buttons-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 30px 0;
}
.social-btn-img {
  width: 5vh;
  margin: 0 5px;
  /* height: 5vh; */
}
.social-btn-off {
  width: 5vh;
  margin: 0 5px;
  filter: grayscale(100%);
  cursor: default;
}
.socials-linked {
  color: #66e2af;
  text-align: center;
  font-size: 1.2rem;
  margin-left: 8px;
  margin-top: 3vh;
  cursor: default;
}
.artist-profile-socials {
  width: 15vw;
  min-width: 120px;
  max-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .artist-profile-bio-container {
  padding: 0 40px;
} */

.artist-profile-bio-row {
  justify-content: space-between;
}
.artist-profile-bio {
  margin-top: 10px;
  font-size: 0.9rem;
  /* margin-right: 10px; */
  text-align: center;
  color: rgba(247, 247, 247, 0.945);
}
.artist-profile-next-show {
  margin-top: 10px;
  font-size: 0.9rem;
  text-align: center;
  color: rgba(247, 247, 247, 0.945);
}
.about-header {
  text-align: left;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  color: #66e2ae;
}
.next-show-header {
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 10px;
  color: #66e2ae;
}

.artist-profile-paragraph {
  text-align: justify;
  justify-content: center;
  padding-right: 10px;
  margin-left: 5px;
  height: 35vh;
}

.artist-profile-main-buttons {
  text-align: right;
  margin: -120px 0 20px -25px;
  height: 120px;
}

.artist-profile-main-btn {
  background-color: #1b194dcb;
  border: 1px solid #ed1650;
  color: #ed1650;
  width: 160px;
  border-radius: 10px;
  font-size: 0.9rem;
  padding: 5px 10px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.artist-profile-main-btn:hover {
  background-color: #ed1650;
  border: 1px solid #ed1650;
  color: #fff;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.artist-profile-main-btn-icon {
  display: none;
  width: 4vh;
  height: 4vh;
  fill: #ed1650;
  background-color: transparent;
  border: 1px solid #ed1650;
  color: #ed1650;
  border-radius: 100%;
  font-size: 0.9rem;
  padding: 5px 7px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.socials-icon {
  width: 30px;
  height: 30px;
}

.artist-profile-upcoming-title {
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  color: #66e2ae;
}
.next-show-img {
  width: 45vh;
  height: 45vh;
}
.next-show-date-time {
  display: flex;
  background-color: #66e2af;
  color: #1b194d;
  font-family: titleFont;
}
.start-streaming-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 8px 0;
  background-color: #66e2af;
  height: 15vh;
  margin-bottom: 5vh;
  color: #1b194d;
}

.next-show-title-ticket-info {
  display: flex;
  justify-content: space-between;
}

.next-show-title {
  font-family: titleFont;
}

.next-show-stream-btn {
  background-color: #888;
  border: 1px solid #222;
  color: #222;
  width: 20vh;
  border-radius: 10px;
  font-size: 0.9rem;
  padding: 5px 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.next-show-stream-btn:hover {
  border: 1px solid #1b194d;
  color: #1b194d;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.artist-profile-divider-container {
  display: flex;
  justify-content: center;
  /* width: 100%; */
}

.line {
  height: 1px;
  width: 60vw;
  margin: 25px 0;
  background: #fff;
}

.merch-store {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  height: 20vh;
}

@media (max-width: 767px) {
  .artist-profile-main-btn-icon {
    display: inline;
  }
  .artist-profile-main-btn {
    display: none;
  }
  .artist-profile-next-show {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .artist-profile-next-show {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }
}
@media (max-width: 992px) {
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }
}
