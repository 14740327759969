#page-break {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 55px;
	padding-bottom: 40px;
	background-color: white;
	color: var(--blue-color);
}

.follow-us {
	color: var(--blue-color);
	font-family: 'titleFont', serif;
}

.follow-us:hover {
	color: var(--red-color);
	text-decoration: none;
}

#ig-feed {
	max-height: 34vh;
	background-color: var(--red-color);
}

.ig-post {
	max-height: 34vh;
	width: 20%;
	object-fit: cover;
}

/* ################################ Footer #################################### */
#footer {
	min-height: 25vh;
	/* margin-left: 8%; */
	display: block;
	background-color: var(--blue-color);
	color: var(--red-color);
}

#footer-baDashboard {
	min-height: 25vh;
	margin-left: 13.75%;
	display: block;
	background-color: var(--blue-color);
	color: var(--red-color);
}

.footer-container {
	margin-left: 15px;
	margin-right: 15px;
}

/* ################################ Logo #################################### */

.footer-logo {
	text-align: center;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 50px;
}

/* ################################ Links #################################### */

.footer-links {
	padding-top: 40px;
}

.link-list {
	list-style: none;
}

.footer-link-tag {
	color: var(--red-color);
}

.footer-link-tag:hover {
	color: var(--mint-color);
	text-decoration: none;
}

/* ################################ Email #################################### */

@media screen and (min-width: 601px) {
	.footer-email-input {
		width: 235px;
	}

	.footer-email-btn {
		width: 160px;
	}
}

@media screen and (max-width: 600px) {
	.footer-email-input {
		width: 100%;
	}

	.footer-email-btn {
		width: 200px;
		margin: 25px auto;
	}

	.footer-email {
		text-align: center;
		padding-left: 25px;
		padding-right: 25px;
	}
}

.footer-email {
	padding-top: 38px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.footer-email-text {
	font-size: small;
	font-family: 'bodyFont', serif;
	text-align: left;
	color: white;
}

.footer-email-input {
	color: var(--mint-color);
	background-color: var(--blue-color);
	border: 1px solid var(--mint-color);
	margin-bottom: 15px;
	margin-right: 5px;
	padding: 10px 10px;
	text-align: left;
	text-decoration: none;
	font-size: 16px;
}

.footer-email-input:focus {
	outline-color: var(--blue-color);
}

.footer-email-btn {
	color: var(--blue-color);
	background-color: var(--mint-color);
	border: none;
	font-family: 'titleFont', serif;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
	transition: all 0.3s ease-in-out;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.footer-email-btn:hover {
	color: white;
	text-decoration: none;
	cursor: pointer;
}

/* ################################ Socials #################################### */

.footer-socials {
	padding-left: 25px;
	padding-right: 50px;
	padding-top: 50px;
	padding-bottom: 50px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-social-icon {
	height: 35px;
	width: 35px;
	margin-left: 20px;
}

/* ################################# Copyright ################################### */

.footer-copyright {
	padding-top: 15px;
	padding-bottom: 25px;
	text-align: center;
	color: white;
	font-size: small;
}

/* ################################# OLD ################################### */

/* .footer-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-section:last-of-type {
  flex-direction: row;
} */

/* .subscribe-form {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.subscribe-field-and-btn {
  display: flex;
  justify-content: center;
}

.footer-brand {
  padding-left: 8vw;
}

.footer-text {
  color: var(--red-color);
}

.footer-text:hover {
  color: var(--mint-color);
  cursor: pointer;
}

.footer-icon {
  color: var(--red-color);
  height: 35px;
  width: 35px;
  margin-right: 18px;
}

.footer-icon:hover {
  color: var(--mint-color);
}

.email-sub {
  font-size: small;
  margin-left: 7px;
  color: #fff;
  margin-bottom: 7px;
} */