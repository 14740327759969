#home-page {
    background: url('./img/homepage-s1-updated.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    margin-top: -180px;
    padding-top: 180px;
}

.headline {
    font-family: titleFont;
    display: inline;
    justify-content: flex-start;
    font-size: 2.5rem;
    /* border-bottom: 0.6rem solid #fff; */
    line-height: 5.5rem;
    pointer-events: none;
}

.headline:last-of-type {
    /* border-bottom: 0.6rem solid rgb(255, 255, 255); */
    transition: all 0.7s ease-in-out;
    /* letter-spacing: -2px; */
    pointer-events: all;
    cursor: pointer;
}

.headline:last-of-type:hover {
    /* border-bottom: 0.6rem solid var(--mint-color); */
    /* letter-spacing: 10px; */
    color: var(--mint-color);
    transition: all 1s ease-in-out;
    text-decoration: none;
}

.teder-center {
    margin: 0 auto;
}

.storyRight p {
    font-size: 1.1em;
    padding-right: 5vw;
    padding-left: 5vw;
}

.storyButtons a {
    text-align: center;
    font-size: 20px;
}

.story-btn.left {
    margin-right: 7px;
}

.story-btn.right {
    margin-left: 7px;
}

.card-grid {
    padding: 3vh 3vw;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
    gap: 1rem;
    text-align: center;
    overflow: "hidden";
}

.flex-column {
    color: var(--blue-color);
}

.show-img {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    border-radius: 15px;
}

.set-img {
    height: 300px;
    width: 300px;
}

.show-info-link {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ed164f9a;
    height: 300px;
    width: 300px;
    font-family: titleFont;
    letter-spacing: 1px;
    /* font-size: 1.5rem; */
    opacity: 0;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.show-info-link:hover {
    background-color: #ed164f83;
    text-decoration: none;
    color: #fff;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}

.show-info-box {
    border: 2px solid #fff;
    border-radius: 15px;
    /* color: var(--blue-color); */
    /* background-color: var(--mint-color); */
    padding: 5px 45px;
}

/* .show-info-box:hover {
  box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.6);
} */

.underline-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
}

.underline-container .underline {
    width: 10px;
    border-width: 4px;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
}

.underline-container:hover .underline {
    width: 145px;
    transition: all 0.7s ease-in-out;
    text-decoration: none;
}

.underline {
    margin-top: 45px;
}

.show-info {
    color: var(--blue-color);
    /* color: var(--red-color); */
    font-family: titleFont;
    align-self: center;
    width: 300px;
    background-color: white;
    /* background-color: var(--blue-color); */
}

.artist-info {
    color: white;
    /* text-align: left; */
    /* margin-left: 12px; */
}

.artist-name {
    font-family: titleFont;
}

/* ################################ Landing #################################### */

#landing {
    min-height: 65vh;
    display: flex;
    padding-left: 5vw;
    padding-right: 5vw;
}

.landing-img {
    height: 100vh;
    width: auto;
}

.headline-container {
    display: flex;
    /* align-items: center; */
    padding-top: 10vh;
}

.headline-title {
    font-family: 'titleFont', serif;
    text-transform: uppercase;
    font-size: 2.5em;
    letter-spacing: 1.5px;
}

.landing-btn {
    background-color: var(--mint-color);
    color: var(--blue-color) !important;
    border: 0px solid transparent;
    padding-top: 0;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-right: 7px;
    line-height: 55px;
    height: 50px;
    width: 230px;
    font-family: 'titleFont', serif;
    font-size: 1rem;
    text-align: center;
    letter-spacing: 1px;
    display: block;
    transition: all 0.3s ease-in-out;

    justify-content: center;
    align-items: center;
}

.landing-btn p {
    margin: 0;
}

.landing-btn:hover {
    cursor: pointer;
    color: #fff !important;
    border-radius: 0px;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}

/* ################################ Shows #################################### */

#shows {
    min-height: 65vh;
    background-color: var(--bg-blue);
    padding-top: 50px;
    padding-bottom: 50px;
}

.shows-header {
    font-family: 'titleFont', serif;
    text-transform: uppercase;
    font-size: 1.8em;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sc-show-card {
    margin-bottom: 25px;
    border-radius: 15px;


}

.sc-show-name {
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 1.3em;
    letter-spacing: 1px;
    -webkit-text-fill-color: var(--red-color);
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 0.7px;
    -webkit-text-stroke-color: var(--blue-color);
    height: 30px;
}

.sc-show-name.max-char {
    font-size: 1em;
}

.sc-artist-name {
    text-transform: uppercase;
    font-size: 1.15em;
    letter-spacing: 0.5px;
    height: 25px;
}

.sc-artist-name.max-char {
    font-size: 0.8em;
}

.sc-show-details {
    margin-top: 5px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-family: 'bodyFont', serif;
}

.sc-show-date {
    text-transform: uppercase;
    font-family: 'titleFont', serif;
    font-size: 1em;
    letter-spacing: 0.3px;
}

.sc-show-location {
    text-transform: uppercase;
    font-family: 'titleFont', serif;
    font-size: 0.9em;
    letter-spacing: 1.3px;
}

.sc-show-genres {
    margin-top: 5px;
    text-transform: lowercase;
    font-size: 0.9em;
    letter-spacing: 1.2px;
}

/* ################################ Shows #################################### */

@media screen and (min-width: 601px) {
    .story-right-text {
        padding-left: 25px;
        padding-right: 25px;
    }

    .story-header {
        text-align: center;
    }

    .story-subtitle {
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (max-width: 600px) {
    .story-right-text {
        padding-left: 3px;
        padding-right: 3px;
    }

    .card-grid {
        margin-left: 10%;
    }

    .story-header {
        text-align: left;
        padding-left: 20px;
    }

    .story-subtitle {
        font-size: 13px;
    }
}

#story {
    background-color: var(--blue-color);
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.story-container {
    margin-left: 15px;
    margin-right: 15px;
}

.story-left {
    background-color: thistle;
    min-height: 55vh;
    background: url('./img/homepage-s2.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.story-right {
    min-height: 55vh;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    align-items: center;
}

.story-right-text {
    padding-top: 50px;
    padding-bottom: 50px;
}

.story-header {
    width: 100%;
    text-transform: uppercase;
    font-family: 'titleFont', serif;
    font-size: 1.4em;
    letter-spacing: 1.3px;
    color: var(--mint-color);
}

.story-subtitle {
    color: var(--mint-color);
    font-family: 'titleFont', serif;
    letter-spacing: 0.5px;
    text-align: center;
}

.story-list {
    width: 100%;
    text-align: left;
    font-size: 15px;
}

.story-list-item {
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.story-btn-placement {
    width: 100%;
    text-align: center;
    padding-top: 45px;
}

.story-btn {
    color: white;
    background-color: var(--red-color);
    border: none;
    padding-top: 15px;
    padding-bottom: 13px;
    padding-left: 35px;
    padding-right: 35px;
    font-family: 'titleFont', serif;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    transition: all 0.3s ease-in-out;
}

.story-btn:hover {
    color: var(--blue-color);
    background-color: var(--mint-color);
    text-decoration: none;
    cursor: pointer;
}

.artist-scheuleshow-detail .card-grid {
	padding: 6vh 0vw;
}
