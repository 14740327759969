.strem-key {
  border-color: var(--red-color);
  border-width: 1px;
  background-color: transparent !important;
  color: #000 !important;
  width: 365px;
  text-align: center;
}

input[type="text"]:focus {
  /* background-color: transparent !important; */
  background-color: transparent;
  color: #000;
}

.mint-color {
  color: var(--mint-color) !important;
}

@media screen and (max-width: 600px) {
  .set-up-stream-container {
    width: 90vw;
  }
}

.set-up-stream-container {
  max-width: 700px;
}
