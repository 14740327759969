:root {
  --red-color: #ed1650;
  --blue-color: #120e42;
  --mint-color: #66e2af;
  --input-blue: #120e4291;
  --bg-blue: #120e42d3;
  --blue-border: #191842;
}

body {
  background: url("../Visuals/background.png") no-repeat center center fixed;
  /* background: linear-gradient(180deg, #881837 0%, #1A1A4A 50%); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  font-family: "bodyFont", serif;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff !important;
}

h1 {
  text-transform: uppercase;
  font-family: "titleFont", serif;
}

h2 {
  font-family: "titleFont", serif;
  text-transform: uppercase;
  font-size: 2em;
  text-align: center;
  margin-bottom: 50px;
}

h3 {
  margin-top: 0.5rem;
  font-family: "titleFont", serif;
  font-size: 1.5em;
  text-transform: uppercase;
  text-align: center;
}

h4 {
  text-align: center;
  text-transform: uppercase;
}

h5 {
  font-family: "titleFont", serif;
  font-size: 1.5em;
  text-transform: uppercase;
}

.teder-red {
  color: var(--red-color);
}

.faded-bg {
  background-color: var(--bg-blue);
}

.teder-caps {
  text-transform: uppercase;
}

.guy {
  background: url("../Components/HomePage/img/homepage-s1-updated.png")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.girl {
  background: url("../Visuals/background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.content {
  min-height: 100vh;
  padding-top: 8vh;
}

/* ############################ Buttons ############################ */

.green-teder-btn, .green-teder-btn-stripe {
  color: var(--blue-color) !important;
  background-color: var(--mint-color) !important;
  border: none;
  padding: 10px 10px;
  margin: 8px 8px;
  max-width: 200px;
  width: 200px;
  font-family: "titleFont", serif;
  line-height: 1.2em;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.green-teder-btn:hover {
  color: #fff !important;
  text-decoration: none;
  cursor: pointer;
}
.green-teder-btn-stripe{
  margin-left: 0;
  margin-top: 20px;
}
.green-teder-btn-stripe:hover{
color: #ed1650 !important;
  
}
.small-red-text{
  color: #ed1650 !important;
}
.note-text{
  
    font-size: 80%;
    font-weight: 400;
    max-width: 570px;
    text-align: left;
    margin-top: 10px;
}
.red-teder-btn {
  color: #fff !important;
  background-color: var(--red-color) !important;
  border: none;
  padding: 10px 25px;
  margin: 8px 8px;
  max-width: 200px;
  font-family: "titleFont", serif;
  font-size: 16px;
  line-height: auto;
  text-align: center;
  line-height: 1.2em;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.red-teder-btn:hover {
  color: var(--blue-color) !important;
  text-decoration: none;
  cursor: pointer;
}

.red-teder-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.fb-btn {
  color: #fff !important;
  background-color: #3b5998;
  border-color: #3b5998;
  width: 400px;
  max-width: 80vw;
  margin-bottom: 10px;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
}

.fb-btn:hover {
  color: #3b5998 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  text-decoration: none;
}

/* ############################ Buttons ############################ */
/* ############################ Forms ############################ */

/* .teder-form-contatiner {
  max-width: 600px;
} */

.teder-form-title {
  color: #fff !important;
  font-family: "titleFont", serif;
  text-transform: uppercase;
  font-size: 2em;
  text-align: center;
  margin-bottom: 50px;
}

.teder-form-label {
  color: #fff !important;
  text-transform: uppercase;
  font-family: "titleFont", serif;
}

.small-green-text {
  color: var(--mint-color);

}


.error-text-red {
  color: var(--red-color);
}
.teder-form-input-field {
  border-color: var(--red-color);
  border-width: 1px;
  background-color: var(--input-blue);
  color: #fff !important;
}

/* ############################ Forms ############################ */
/* ############################ Modals ############################ */

@media screen and (min-width: 601px) {
  .teder-modal-title {
    font-size: 24px;
  }

  .teder-modal-subtitle {
    font-size: 20px;
  }

  .teder-modal-body {
    font-size: 16px;
    max-width: 425px;
  }
}

@media screen and (max-width: 600px) {
  .teder-form-contatiner {
    max-width: 90vh;
  }

  .teder-modal-title {
    font-size: 22px;
  }

  .teder-modal-subtitle {
    font-size: 16px;
  }

  .teder-modal-body {
    font-size: 14px;
  }
}

.Modal {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 5px 8px 10px rgb(26, 25, 25);
  border: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-blue);
  z-index: 999;
}

.teder-modal-container {
  padding: 20px 20px;
}

.teder-modal-title {
  color: var(--red-color);
  font-family: "titleFont", serif;
  text-transform: uppercase;
}

.teder-modal-subtitle {
  color: var(--blue-color);
  font-family: "titleFont", serif;
  text-transform: uppercase;
  padding-top: 15px;
}

.teder-modal-body {
  color: var(--blue-color);
  font-family: "bodyFont", serif;
  padding: 15px 0px 15px 0px;
}

/* ############################ Modals ############################ */

.mainTitle {
  color: #fff !important;
  text-align: left;
  font-family: "titleFont", serif;
  font-size: 9em;
  max-width: 100vw;
}

.subTitle {
  /* color:#fff !important; */
  text-align: center;
  margin-bottom: 50px;
  font-family: "titleFont", serif;
}

.btn-custom {
  color: #1b194d;
  background-color: #66e2af;
  border-color: #66e2af;
  font-family: "titleFont", serif;
  text-transform: uppercase;
  min-width: 90px;
  letter-spacing: 0.05em;
}

.btn-custom.left {
  margin-right: 7px;
}

.btn-custom:hover {
  color: #fff !important;
  background-color: var(--red-color);
  border-color: var(--red-color);
  text-decoration: none;
}

.btn-custom2 {
  color: #1b194d;
  background-color: gray;
  border-color: #66e2af;
}

.btn-teder {
  margin-left: 5px;
  color: #fff !important;
  background-color: var(--red-color);
  border-color: var(--red-color);
  font-family: "titleFont", serif;
}

.btn-teder:hover {
  color: #1b194d;
  background-color: #66e2af;
  border-color: #66e2af;
  text-decoration: none;
}

.teder {
  border-color: var(--red-color);
  border-width: 1px;
  /* background-color: transparent; */
  background-color: #1b194d56;
  color: #fff !important;
}

.teder textarea {
  border-color: var(--red-color);
  border-width: 1px;
  /* background-color: transparent; */
  background-color: #1b194d56;
  color: #fff !important;
}
.teder textarea:focus {
  border-color: var(--red-color);
  border-width: 1px;
  /* background-color: transparent; */
  background-color: #1b194d56;
  color: #fff !important;
}

.durationRange:focus {
  background-color: #1b194d56;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #1b194d56;
}

.textbox-style {
  border-color: #66e2af;
  border-width: 1px;
  background-color: transparent;
  width: 190px;
}

.outputMessage {
  color: var(--red-color);
  font-family: "bodyFont", serif;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A6A6A6 !important;
  opacity: 1; /* Firefox */
}

::-webkit-input-placeholder {
  /* Edge */
  color: var(--red-color);
}

input[type="text"]:focus {
  background-color: transparent !important;
  color: #fff !important;
}

input[type="email"]:focus {
  background-color: transparent !important;
  color: #fff !important;
}

input[type="number"]:focus {
  background-color: transparent !important;
  color: #fff !important;
}

select:focus {
  background-color: transparent !important;
  color: var(--red-color);
}

@media (min-width: 500px) {
  .texboxWrap {
    size: 140px;
    float: left;
  }
  .buttonWrap {
    float: right;
    padding-left: 10px;
  }

  .logo {
    width: 100px;
  }

  .recaptcha-wrap {
    padding-top: 20px;
  }
}

@media (max-width: 500px) {
  .texboxWrap {
    size: 140px;
  }
  .buttonWrap {
    padding-top: 10px !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    text-align: center !important;
  }
  .buttonWrap button {
    justify-content: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
  }
}

.upload-photo-form {
  margin-bottom: 3vh;
}

.static-page {
  padding-top: 3vh;
  margin-bottom: 10vh;
  background-color: #1b194db2;
}

.static-link {
  color: var(--red-color);
}

.contact-us {
  margin-left: 0;
  margin-right: 0;
}
