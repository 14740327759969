.edit-profile-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.input-control-artist-edit {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-color: var(--red-color);
  border-width: 1px;
  background-color: var(--input-blue);
  color: white;
}

.input-control-artist-edit:focus * {
  color: white;
}
