.ba-schedule-form-content {
    padding: 5px 0 0 96px;
}


.ba-schedule-form-content .form-control {
    position: relative;
    padding: 23px 12px;
    background: #FFFFFF;
    border: 1px solid #191842;
    border-radius: 10px;
    width: 100%;
    color: #1A1A4A;
}
select#selectTimezone{
    padding: inherit;
    height: 48px;
    line-height: 48px;
}
.img-artist-block{
    display: flex;
    align-items: center;
}
.img-block{
    width: 40px;   
    height: 40px;
    border-radius: 100px;
    margin-right: 15px;
}
.img-block img{
    max-width: 100%;
}
.artist-name{
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;

}
.ba-schedule-form-content .form-control:focus, 
.ba-schedule-form-content input[type="text"]:focus{
    color: #1A1A4A !important;
    background-color: #fff !important;
    border-color:#191842 !important;
    outline: 0 !important;
    box-shadow:none !important;
}
.ba-schedule-form-content .form-group.genre-dropdown{
    margin-bottom: 0 !important;
}

.ba-schedule-form-content  .teder-select option {
    background: #fff !important;
    color: #1A1A4A !important;
}

.ba-schedule-form-content .form-control fieldset:focus{
   border-color: transparent !important;
    border-width: 0px !important;
}
.ba-schedule-form-content .teder-form-label {
    font-style: normal;
    font-weight: 700;
    font-size: 17px !important;
    color: #191842 !important;
    text-transform: capitalize;
}

.ba-schedule-form-content h2 {
    font-weight: 700;
    font-size: 24px;
    text-transform: capitalize;
    text-align: left;
   width: calc(100% - 17%);
   padding: 0 1%;
    color: #000000;
}

.ba-schedule-form-content form {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 78%;
    margin: 0 auto;
}

.ba-schedule-form-content #bio,
.ba-schedule-form-content #message,
.ba-schedule-form-content #inputAbout,
.ba-schedule-form-content #help:focus {
    color: #1A1A4A;
    background-color: #fff !important;
}

.ba-schedule-form-content form .error-info{
    color: #ef2a2a;
}
.ba-schedule-form-content .music-genre-label{
    display: none !important;
}
.ba-schedule-form-content .multi-select{
    background-color: #fff !important;
    --rmsc-bg:#fff !important; 
    border: 1px solid #191842;
}
.ba-schedule-form-content .dropdown-heading-value, .dropdown-container {
    color: #1A1A4A !important;
}
.ba-schedule-form-content form .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color:none !important;
    border-width: 0px !important;
}
.ba-schedule-form-conten .form-group.genre-dropdown{
    overflow: inherit !important;
}
.ba-schedule-form-content .dropdown-heading{
    border-radius: 10px;
     padding: 23px 12px;
     border: transparent 1px solid !important;
}
.ba-schedule-form-content .multi-select {
    --rmsc-main: #1A1A4A !important;
    --rmsc-hover: #1A1A4A !important;
    --rmsc-selected: var(--mint-color)!important;
    --rmsc-border: #191842 !important;
    --rmsc-gray: #fff !important;
    --rmsc-bg: #fff !important;
    border-radius: 10px;
}
.ba-schedule-form-content .multi-select .dropdown-container{
    border: none !important;
    border-radius: 10px
}
.ba-schedule-form-content .gray{
    color: #1A1A4A !important;
}
.ba-schedule-form-content  button {
    width: 24px;
    height: 34px;
    margin-top: 12px;
}
.ba-schedule-form-content .genre-options-item {
  font-family: bodyFont !important;
  color: var(--blue-color);
}
.ba-schedule-form-content .select-item {
  font-family: bodyFont !important;
  color: #1A1A4A !important;
}
.ba-schedule-form-content .select-item::before {
  color: #1A1A4A !important;
}
.ba-schedule-form-content .select-item:hover {
  color: var(--blue-color)!important;
  text-decoration: none;
}
.ba-schedule-form-content .multi-select {
  --rmsc-main: #f5f5f5!important;
  --rmsc-hover: #f5f5f5!important;
  --rmsc-selected: #ccc !important;;
  --rmsc-border:#FF00FF !important;
  --rmsc-gray: #f5f5f5 !important;
  --rmsc-bg:#fff !important;
}
.music-genre-label, .genre-dropdown, .dropdown-heading-value, .dropdown-container{
  color: #ff0000 !important;
}
.upload-photo {
    border: 1px solid #191842;
    border-radius: 10px;
    height: 100%;
    min-height: 321px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.ba-schedule-form-content textarea{
    border: none !important;
    min-height: 321px;
    resize: none;
}
.upload-photo .upload-btn p{
    font-size: 17px;
}
.upload-photo .edit-icon{
    display: none !important;
}
.btn-block{
    padding:8% 0;
    justify-content: space-between;
    width: 76%;
    margin: 0 auto;
}
.btn-block .btn{
max-width: 224px;
padding: 17px 15px;
border: 1px solid #191842;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 20px;
text-align: center;
font-style: normal;
font-weight:100;
font-size: 17px;
text-transform: uppercase;
color: #191842;
width: inherit;
height: inherit;
text-shadow: none !important;
margin-top: 0;
}
.btn-block .btn-primary{
    background: #EE1D50;
    border: 1px solid #EE1D50;
    color: #fff;
}
.btn-block .btn-primary:hover, 
.btn-block .btn-primary:focus{
     background: #EE1D50 !important;
     border: 1px solid #EE1D50 !important;
     color: #fff;
}
.btn-secondry{

}
.upload-photo-textarea{
    align-items: flex-start !important;
}
.ba-schedule-form-content .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: transparent !important;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  /* color: #191842 !important; */
}
::-moz-placeholder { /* Firefox 19+ */
  /* color: #191842 !important; */
}
:-ms-input-placeholder { /* IE 10+ */
  /* color: #191842 !important; */
}
:-moz-placeholder { /* Firefox 18- */
  color: #191842 !important;
}

.ba-schedule-form-content .img-box-wrapper-child {
    height: 100%;
    width: 100%;
    border-radius: 0;
    overflow: hidden;
       display: flex;
    align-items: center;
}

.ba-schedule-form-content .img-editor-container, 
.ba-schedule-form-content .img-box-filled, 
.ba-schedule-form-content .img-box{
    width: 100%;
    height: 100%;
}
/* .ba-schedule-form-content .img-box-filled, .ba-schedule-form-content .img-box{
        height: 90%;
    width: 90%;
} */
.ba-schedule-form-content .img-box {
    display: flex;
    /* height: 118px;
    width: 118px;
    border-radius: 50%; */

    border-radius: 10px;
    background-color: #efefef;
    margin-right: 0px;
}
.ba-schedule-form-content .img-box-filled {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background-color: #1b194d56;
    border: none;
    margin-bottom: 0;
    margin-right: 0;
    overflow: hidden;
    margin: 0 auto;
}

.ba-schedule-form-content  .img-box-button {
    position: absolute;
    top: 4%;
    right: 4%;}
@media only screen and (min-width:768px) and (max-width:989px) {
    .ba-schedule-form-content {
    padding: 0px 10px 0 47px;
}
.ba-schedule-form-content form{
    width: 100%;
}
.d-flex.btn-block{
    margin-top: 30px;
}
}
    @media screen and (max-width: 600px) {
        .ba-schedule-form-content {
    padding: 0px 10px 0 10px;
}
.ba-schedule-form-content form{
    width: 100%;
}
.mob-mb-0{
    margin-bottom: 0;
}
.upload-photo{
    min-height: 321px;
    max-height: 321px;
    height: 321px;
}
.upload-photo-textarea{
        min-height: inherit !important;
    max-height:  inherit !important;
    height: inherit !important;
}
.btn-block .btn{
    margin-left: 5px;
    margin-right: 5px;
}

    }

    .timeText {
        color:#191842
    }

    .timeForm {
    margin: 0 0 0 1%;
    border: 1px solid #191842;
    border-radius: .25rem;
    position: relative;
    padding: 2% 2.5%;
    background: #FFFFFF;
    border-radius: 10px;
    height: 3rem;
    width: 25%;
    color: #1A1A4A;
    }

    .timeFormArtist {
        margin: 0 2% 0 1%;
        border: 1px solid red;
        border-radius: .25rem;
        position: relative;
        padding: 2% 2.5%;
        background: #1A1A4A;
        border-radius: 3px;
        height: 2.39rem;
        width: 30%;
        color: #fff;
    }