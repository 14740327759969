.master-center {
    /* margin-top: auto;
    margin-bottom: auto; */
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
}
.left-text{
    text-align: left !important;
}
.overlay {
    background: #706f6f;
    position: absolute;  
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.3;
}
.tableWhite {
    color:white;
}
.teder-top-margin {
    margin-top: 1rem;
}
.OBSList li {
    margin-top:20px;
}
.adminWrap {
    margin-left:20px;
    margin-right:20px;
}
.adminRow {
    display: block;
}
/**********************************Admin Table Grid Css***********************/
.adminWrapBlock h3{
    padding-top: 20px;
    padding-bottom: 20px;
}
.adminWrapBlock table.dark {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
}
.adminWrapBlock table.dark td, 
.adminWrapBlock table.dark th, 
#user-tab-content .MuiDataGrid-virtualScrollerRenderZone  .MuiDataGrid-cell {
    padding: 0.75rem;
    vertical-align: top;
    border: 1px solid rgba(160,175,185,.15);
    color: #838aa0!important;
    border-bottom: none;    
}
.adminWrapBlock table.dark tr:last-child td{
     border-bottom: 1px solid rgba(160,175,185,.15);
}
.adminWrapBlock table.dark th{
border-bottom: none;
background-color: rgb(51 20 54 / 55%);
}
.adminWrapBlock table.dark tbody td, #user-tab-content .MuiDataGrid-virtualScrollerRenderZone  .MuiDataGrid-cell {
    background-color: rgb(63 25 67 / 12%);
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.adminWrapBlock table.dark td:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
}
.adminWrapBlock table.dark tbody td button{
    margin: 0;
}
#user-tab-content{
margin-top: 25px;
}
#user-tab-content .admin-data-grid, #user-tab-content  .css-1e2bxag-MuiDataGrid-root{
    border:1px solid rgba(160,175,185,.15)
}
#user-tab-content .MuiDataGrid-columnHeaders.css-f3jnds-MuiDataGrid-columnHeaders{
    border-bottom: none;
    background-color: rgb(51 20 54 / 55%);
    font-size: 1rem;
}
#user-tab-content .MuiDataGrid-iconSeparator{
    display: none !important;
}
#user-tab-content .MuiDataGrid-footerContainer{
    border-top:1px solid rgba(160,175,185,.15);
    background-color: rgb(51 20 54 / 55%);
}
#user-tab-content .MuiDataGrid-footerContainer button{
    width: auto !important;
    height: auto !important;
}
#user-tab-content .MuiDataGrid-footerContainer p{
    margin-bottom: 0 !important;
}
#user-tab-content .MuiDataGrid-footerContainer button, 
#user-tab-content .MuiDataGrid-footerContainer, 
#user-tab-content .MuiDataGrid-columnHeaders.css-f3jnds-MuiDataGrid-columnHeaders, 
.adminWrapBlock table.dark th, .adminWrapBlock table.dark tbody td, 
#user-tab-content .MuiDataGrid-virtualScrollerRenderZone  .MuiDataGrid-cell, 
#user-tab-content .MuiDataGrid-footerContainer p, 
#user-tab-content .MuiDataGrid-columnHeader:hover, 
#user-tab-content .MuiDataGrid-columnHeader:hover{
    color: #b0b3bc!important;
}
#user-tab-content .MuiDataGrid-toolbarContainer{
 background-color: #311231 !important;
 text-align: right;
}
#user-tab-content .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root, 
#user-tab-content .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover, 
#user-tab-content .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:focus,
#user-tab-content .MuiButton-textSizeSmall.MuiButtonBase-root.css-1knaqv7-MuiButtonBase-root-MuiButton-root,
#user-tab-content .MuiButton-textSizeSmall.MuiButtonBase-root.css-1knaqv7-MuiButtonBase-root-MuiButton-root:hover, 
#user-tab-content .MuiButton-textSizeSmall.MuiButtonBase-root.css-1knaqv7-MuiButtonBase-root-MuiButton-root:focus {
    width: auto !important;
    float: right;
    outline: none !important;
    border: none !important;
}

#user-tab-content .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root svg {
  fill: #b0b3bc!important;
}

/**********************************Admin Table Grid Css***********************/
#spinner{
    position: fixed;
    top: 50%;
    left: 50%;
}