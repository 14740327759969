/* @media screen and (max-width: 600px) {
    .step-one-container{
        width: 90vw;
    }
}

.step-one-container{
    max-width: 600px;
}

.step-one-form-input{
    border-color: var(--red-color);
    border-width: 1px;
    background-color: var(--input-blue);
    color: #fff !important;
}

input[type="text"]:focus {
    background-color: var(--input-blue);
    color: #ffffff !important;
}


.step-one-button-container{
    padding-top: 25px;
    text-align: center;
    padding-bottom: 50px;
} */

.sign-up-form-1-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.sign-up-form-1-content {
  display: flex;
  width: 858px;
  height: 750px;
  background: rgba(27, 25, 77, 0.65);
  border-radius: 10px;
  margin-bottom: 70px;
  flex-direction: column;
  align-items: center;
}

.sign-up-form-1-form {
  width: 638px;
  height: 342px;
  display: flex;
}

.sign-up-title p {
  font-family: "signInFont", sans-serif;
  font-size: 16px;
  margin-top: 27px;
  margin-bottom: 19px;
}

.first-last-input {
  display: flex;
}

form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.name-input,
.last-input {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.name-input input,
.last-input input {
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 11px;
  padding-top: 2px;
}

.name-input label,
.last-input label {
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "proximaNovaBold";
  font-weight: 600;
}

.name-input p {
  font-size: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  color: rgba(237, 27, 80, 1);
}

.name-input input {
  width: 292px;
  height: 30px;
  margin-right: 42px;
}

.last-input input {
  width: 304px;
  height: 30px;
}

.address-input {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin-bottom: 18px;
}

.address-input label {
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "proximaNovaBold";
  font-weight: 600;
}

.address-input input {
  height: 30px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 11px;
  padding-top: 2px;
}

.city-state-zip-input {
  display: flex;
  margin-bottom: 18px;
}

.city-input,
.state-input,
.zip-input {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.city-input label,
.state-input label,
.zip-input label {
  font-size: 14px;
  margin-bottom: 7px;
  font-family: "proximaNovaBold";
  font-weight: 600;
}

.state-input p {
  font-size: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  color: rgba(237, 27, 80, 1);
}

.city-input input,
.zip-input input {
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 11px;
  padding-top: 2px;
}

.city-input input {
  width: 292px;
  height: 30px;
  margin-right: 44px;
}

.state-input select {
  width: 133px;
  height: 30px;
  margin-right: 34px;
  border: none;
  border-radius: 4px;
  padding-left: 8px;
  padding-top: 2px;
}

.state-input select:focus {
  background-color: yellow;
}

.zip-input input {
  width: 133px;
  height: 30px;
}

.country-email-input {
  display: flex;
  margin-bottom: 18px;
}

.country-input,
.email-input {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.country-input label,
.email-input label {
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "proximaNovaBold";
  font-weight: 600;
}

.country-input select {
  width: 302px;
  height: 30px;
  margin-right: 32px;
  border: none;
  border-radius: 4px;
  padding-left: 8px;
  padding-top: 2px;
}

.email-input input {
  width: 302px;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 11px;
  padding-top: 2px;
}

.phone-referral-input {
  display: flex;
}

.phone-input,
.referral-input {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.phone-input label,
.referral-input label {
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "proximaNovaBold";
  font-weight: 600;
}

.phone-input input {
  width: 302px;
  height: 30px;
  margin-right: 32px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 11px;
  padding-top: 2px;
}

.referral-input input {
  width: 302px;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 11px;
  padding-top: 2px;
}

.next-button-container {
  display: flex;
  justify-content: center;
}

.next-button {
  margin: 40px;
  width: 292px;
  height: 34px;
  background: #ed1650;
  color: white;
}
.disabled {
  margin-top: 40px;
  width: 292px;
  height: 34px;
  background: #ed1650;
  opacity: 0.5;
  color: white;
}
.next-button p {
  font-size: 13px;
}

/* @media (max-width: 600px) {
  .sign-up-form-1-container {
    flex-direction: column;
    width: 75vw;
  }
  .sign-up-form-1-form {
    width: 75vw;
    height: 342px;
    display: flex;
    flex-direction: column;
  }
} */
