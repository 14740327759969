.login-options {
  padding-top: 15vh;
}
:where(.css-dev-only-do-not-override-1i536d8).ant-picker .ant-picker-input > input {
	color:white;

	font-size: 40px;
	/* text-shadow: -1px 1px 0 #425b95,
				  1px 1px 0 #425b95,
				 1px -1px 0 #425b95; */

  font-size: 1rem;
  /* top: -20px;
  position: absolute; */
  text-align: center;
  font-family: 'titlefont';
}



.btn-signup {
  color: var(--blue-color);
  background-color: var(--mint-color);
  border-color: var(--mint-color);
  width: 35vw;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-signup:hover {
  color: var(--mint-color);
  background-color: white;
  border-color: white;
  text-decoration: none;
}

.sign-in-container {
  position: absolute;
  width: 479px;
  top: 152px;
  background: rgba(27, 25, 77, 0.65);
  border-radius: 10px;
}

.sign-in-form {
  display: flex;
  flex-direction: column;
  padding: 36px 94px 36px 94px;
  text-align: center;
}

.sign-in-text {
  display: flex;
  justify-content: center;
  align-items: left;
  padding-bottom: 30px;
}

.sign-in-text h3 {
  font-size: 16px;
  letter-spacing: 2%;
}

.sign-in-form label {
  font-family: "signInFont", sans-serif;
  font-size: 13px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 4px;
}

.sign-in-form input {
  height: 35px;
  width: 100%;
  background-color: #f4f3ff;
  border-radius: 6px;
  border: none;
  margin-bottom: 18px;
  padding-left: 10px;
  padding-top: 2px;
}

.sign-in-form input:focus {
  /* background-color: #f4f3ff; */
  outline: none;
}

.sign-in-additional {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
  justify-content: space-between;
}

.remember-me-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.remember-me-btn input {
  position: relative;
  top: -2px;
  width: 15px;
  height: 15px;
  margin: 0;
  outline: none;
  border: 1px solid #f4f3ff;
  box-sizing: border-box;
  border-radius: 2px;
}

.remember-me-btn h3 {
  text-align: center;
  padding-left: 5px;
  margin: 0;
  text-transform: none;
  letter-spacing: 2%;
  font-family: "signInFont-2", sans-serif;
  font-size: 13px;
}

.forgot-password h3 {
  margin: 0;
  font-size: 13px;
  text-decoration: underline;
  text-transform: none;
  letter-spacing: 2%;
  font-family: "signInFont-2", sans-serif;
}

.email-login-btn {
  margin-top: 26px;
  margin-bottom: 25px;
  height: 35px;
  font-size: 15px;
  font-weight: 700;
  color: white;
  border: none;
  outline: none;
  background-color: #ed1650;
  font-family: "titleFont", sans-serif;
  letter-spacing: 3%;
}

.divider {
  margin-top: 1.5em;
  position: relative;
  font-size: 10px;
  margin-bottom: 0;
  text-transform: none;
}

.divider::before {
  content: "";
  display: block;
  width: 45%;
  height: 1px;
  position: absolute;
  background: #fff;
  left: 0;
  top: 40%;
}

.divider::after {
  content: "";
  display: block;
  width: 45%;
  height: 1px;
  position: absolute;
  background: #fff;
  right: 0;
  top: 40%;
}

.facebook-login-btn {
  margin-top: 1em;
  margin-bottom: 1.5em;
  height: 35px;
  width: 100%;
  background-color: #425b95;
  border: none;
  outline: none;
}

.facebook-login-btn span {
  padding-left: 10px;
  color: #fff;
  font-size: 13px;
}

.fa-facebook-f {
  color: white;
}

.need-account h3 {
  font-size: 12px;
  font-family: "signInFont-2", sans-serif;
  text-transform: none;
}

.need-account a {
  text-decoration: underline;
  color: white;
}

.need-account a:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* .btn-fb {
  color: white;
  background-color: #3b5998;
  border-color: #3b5998;
  width: 35vw;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-fb:hover {
  color: #3b5998;
  background-color: white;
  border-color: white;
} */

.btn-gg {
  color: var(--blue-color);
  background-color: white;
  border-color: white;
  width: 35vw;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-gg:hover {
  color: white;
  background-color: #4885ed;
  border-color: #4885ed;
  text-decoration: none;
}

.btn-em {
  color: white;
  background-color: var(--red-color);
  border-color: var(--red-color);
  width: 35vw;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-em:hover {
  color: var(--red-color);
  background-color: white;
  border-color: white;
  text-decoration: none;
}

.artist-signup {
  padding-top: 8vh;
  padding-bottom: 18vh;
  width: 50vw;
}

.artist-signup3 {
  padding-bottom: 8vh;
}

.btn-agree {
  color: var(--blue-color);
  background-color: var(--mint-color);
  border-color: var(--mint-color);
  width: 35vw;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.btn-agree:hover {
  color: var(--blue-color);
  background-color: white;
  border-color: var(--mint-color);
  text-decoration: none;
}

.btn-close {
  color: white;
  background-color: var(--blue-color);
  border-color: var(--blue-color);
  width: 35vw;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.btn-close:hover {
  color: var(--blue-color);
  background-color: white;
  border-color: white;
  text-decoration: none;
}

label {
  font-family: "titleFont", serif;
}

.astrek {
  color: var(--mint-color);
}

.arrow {
  padding-top: 3vh;
  text-align: center;
  font-size: 20px;
}

.arrow:hover {
  color: var(--red-color);
  cursor: pointer;
  text-decoration: none;
}

.arrow.go {
  color: var(--mint-color);
  margin-right: 45px;
}

.arrow.go:hover {
  color: var(--red-color);
  cursor: pointer;
  text-decoration: none;
}

.signup-text {
  color: var(--mint-color);
  margin-left: 5px;
}

.signup-text:hover {
  color: var(--red-color);
  cursor: pointer;
  text-decoration: none;
}

.artist-textbox-style {
  border-color: var(--red-color);
  border-width: 1px;
  background-color: var(--input-blue);
  color: white;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  opacity: 1; /* Firefox */
}

::-webkit-input-placeholder {
  /* Edge */
  color: white;
}
.teder-select-selected {
  background-color: yellow;
}
.teder-select-items {
  background-color: DodgerBlue;
}
.teder-select option {
  background: rgba(0, 151, 19, 0.1) !important;
  color: black;
}

.teder-select option:checked,
option:hover {
  color: red;
  background: #488f8f repeat url("data:image/gif;base64,R0lGO...");
  text-decoration: none;
}

input[type="text"]:focus {
  /* background-color: transparent !important; */
  background-color: var(--input-blue) !important;
  color: white;
}
input[type="url"]:focus {
  background-color: var(--input-blue) !important;
  color: white;
}
input[type="tel"]:focus {
  background-color: var(--input-blue) !important;
  color: white;
}

select:focus {
  background-color: transparent !important;
  color: var(--red-color);
}

.upload-imgs {
  height: auto;
  width: 100%;
  background-color: #e6e7e8;
}

textarea {
  min-height: 40vh;
}

.signup-success {
  padding-top: 15vh;
  text-align: center;
}

.schedule-new {
  color: var(--mint-color);
}

.schedule-new:hover {
  color: var(--red-color);
  text-decoration: none;
}

.or-text {
  color: white;
}

/* Remove Arrows from number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

#bio,
#message,
#inputAbout,
#help:focus {
  color: #fff;
  background-color: var(--input-blue) !important;
}

#state:focus {
  color: #fff;
}
#state:disabled {
  background-color: rgba(40, 40, 40, 0.26);
  color: rgb(90, 90, 90);
  border-color: rgb(90, 90, 90);
}
.state-label-disabled {
  color: rgb(90, 90, 90);
}
#state-placeholder {
  color: rgba(255, 255, 255, 0.575) !important;
}
#country:focus {
  color: #fff;
}

.modal-body {
  color: var(--blue-color);
  font-family: "bodyFont", serif;
}

.profile-img {
  height: 200px;
  width: 200px;
}

.ticket-form {
  padding-left: 30px;
  padding-right: 30px;
}

.account-wrong-creds {
  color: red;
}
