/* .header-container {
	display: none !important;
} */

.content {
  margin: 0;
  padding: 0;
}

/* #footer {
	display: none !important;
} */

.dashboard-container {
  width: 100%;
  height: auto;
}

.dashboard-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.right-side {
  display: flex;
  flex-direction: column;
  width: 88%;
  margin-left: 12%;
  height: 100%;
}

.top-bar {
  height: 81px;
  width: calc(100% - 12%);
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-right: 96px;
  filter: drop-shadow(0px 4px 10px rgba(219, 53, 84, 0.08));
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  /* margin-bottom: 20px; */
}

.top-bar-text {
  color: #1a1a4a;
  font-family: "titleFont", sans-serif;
  font-size: 16px;
  margin: 0;
}

.date-logout {
  display: flex;
}

.current-date-text {
  color: #1a1a4a;
  font-family: "titleFont", sans-serif;
  font-size: 16px;
  margin-bottom: 0;
  margin-right: 63px;
}

.agent-name {
  color: #1a1a4a;
  font-family: "titleFont", sans-serif;
  font-size: 16px;
  margin: 0;
  margin-left: 5px;
}

.logout-container {
  display: flex;
  flex-direction: row;
  width: 20%;
}
.highest-earning-artists svg.MuiSvgIcon-fontSizeMedium {
	display: none;
}
.highest-earning-artists span.MuiTypography-p{
  display: block;
padding-left: 15px;
padding-bottom: 10px;
}