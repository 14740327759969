.topnavbar {
  display: flex;
  top: 0;
  height: 100px;
  justify-content: space-between;
  padding: 0 8vw;
  /* padding-top: 3vh;
  padding-left: 8vw;
  padding-right: 8vw;
  margin-top: -2vh; */
}

.logo-container {
  position: absolute;
  margin-left: calc(50vw - 50px);
  left: 0;
  top: 1vh;
}

.menu {
  cursor: pointer;
}

/* .navbar-brand {
  display: flex;
  width: 100%;
  justify-self: center;
  margin-left: 2vw;
} */

.profile {
  margin-right: 2vw;
}

.hide-btn {
  text-align: right;
  cursor: pointer;
  margin-top: 3vh;
}

.sidebar-content {
  color: white;
  padding-top: 25vh;
  font-size: 1.2em;
}

.sidebar-text:hover {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.sidebar-text:hover {
  color: var(--red-color);
  cursor: pointer;
  text-decoration: none;
}

.login-text {
  font-size: 1rem;
}

/* ################################ Beta Header ####################################

.beta-header{
  background-color: var(--blue-color);
  color: var(--red-color);
  height: 45px;
  padding-top: 12px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-family: "titleFont", serif;
  font-size: 16px;
  text-align: center;
  letter-spacing: 8px;
} */

/* ################################ Topbar #################################### */

.header-container {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 65px;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* background-color: thistle; */
  height: 85px;
}

.header-menu {
  color: var(--blue-color);
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  width: 25vw;
}

.header-logo {
  color: var(--blue-color);
  text-align: center;
  width: 50vw;
  padding-left: 10px;
}

.header-shortcuts {
  color: var(--blue-color);
  text-align: center;
  width: 25vw;
}

/* ################################ Sidebar #################################### */

.sidebar {
  height: 102vh;
  padding-left: 20px;
  background-color: #1b194d;
  color: white;
  width: 400px;
  max-width: 90vw;
  position: fixed;
  z-index: 200;
  top: 0;
  left: -100%;
  animation: slide-open 0.75s forwards;
  box-shadow: 0 0 5px 10px rgba(0, 0, 0, 0.3);
}

.sidebar.hide {
  left: 0;
  animation: slide-hide 1.25s forwards;
}

@keyframes slide-open {
  100% {
    left: 0;
  }
}

@keyframes slide-hide {
  100% {
    left: -100%;
  }
}

.sidebar-link {
  color: white;
  text-transform: uppercase;
  font-family: "titleFont", serif;
  letter-spacing: 1px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sidebar-link:hover {
  color: var(--red-color);
  cursor: pointer;
  text-decoration: none;
}

