.genre-options-item {
  font-family: bodyFont !important;
  color: var(--blue-color);
}
.select-item {
  font-family: bodyFont !important;
  color: #fff !important;
}
.select-item::before {
  background-color: black;
  color: #fff !important;
}
.select-item:hover {
  color: var(--blue-color)!important;
  text-decoration: none;
}

.multi-select {
  --rmsc-main: #fff!important;
  --rmsc-hover: #fff!important;
  --rmsc-selected: var(--mint-color)!important;;
  --rmsc-border: var(--red-color)!important;
  --rmsc-gray: #fff !important;
  --rmsc-bg:var(--blue-color)!important;
}

.music-genre-label, .genre-dropdown, .dropdown-heading-value, .dropdown-container{
  color: #fff !important;
}