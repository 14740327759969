.sign-up-form-2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.sign-up-form-2-content {
  display: flex;
  width: 858px;
  height: 600px;
  background: rgba(27, 25, 77, 0.65);
  border-radius: 10px;
  margin-bottom: 70px;
  flex-direction: column;
  align-items: center;
}

.sign-up-form-2-info-container {
  display: flex;
  flex-direction: row;
}

.sign-up-form-2-form {
  width: 657px;
  height: 342px;
  display: flex;
}

.artist-form-info {
  width: 510px;
}

.stage-name-music-genre-input {
  display: flex;
}

.artist-form-profile-pic-container {
  background-color: #e9e8f6;
  height: 118px;
  width: 118px;
  border-radius: 50%;
  margin-right: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.artist-form-profile-pic-container p {
  margin: 0;
  font-size: 11px;
  color: rgba(27, 25, 77, 1);
  font-family: "proximaNovaBold", sans-serif;
}

.stage-name-input input {
  width: 243px;
  height: 30px;
  padding-left: 11px;
  border-radius: 4px;
  margin-right: 24px;
  border: none;
  outline: none;
}

.stage-name-input label,
.music-genre-input label {
  margin-bottom: 7px;
  font-family: "proximaNovaBold", sans-serif;
  font-size: 14px;
}

.music-genre-input {
  display: flex;
  width: 243px;
  height: 30px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.stage-name-input,
.music-genre-input {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.bio-input {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.bio-input label {
  margin-top: 7px;
  margin-bottom: 7px;
  font-family: "proximaNovaBold", sans-serif;
  font-size: 14px;
}

.textarea-container {
  width: 510px;
  height: 128px;
  background-color: white;
  border-radius: 4px;
  position: relative;
}

.bio-input textarea {
  margin-top: 7px;
  resize: none;
  min-height: 90px;
  width: 510px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 7px;
}

.bio-input span {
  position: absolute;
  left: 0%;
  top: 85%;
  display: flex;
  width: 100%;
  justify-content: right;
}

.out-of-300 {
  margin-right: 7px;
}

.out-of-300 p {
  color: rgba(27, 25, 77, 0.5);
  font-size: 14px;
}

.current-text-count p {
  color: rgba(27, 25, 77, 0.5);
  font-size: 14px;
}

.sound-cloud-tiktok-input,
.instagram-youtube-input {
  display: flex;
  flex-direction: row;
  text-align: left;
}

.sound-cloud-input,
.instagram-input {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  position: relative;
}

.sound-cloud-input label,
.instagram-input label {
  margin-top: 7px;
  margin-bottom: 7px;
  font-family: "proximaNovaBold", sans-serif;
  font-size: 14px;
}

.tik-tok-input,
.youtube-input {
  display: flex;
  position: relative;
  flex-direction: column;
}

.tik-tok-input label,
.youtube-input label {
  margin-top: 7px;
  margin-bottom: 7px;
  font-family: "proximaNovaBold", sans-serif;
  font-size: 14px;
}

.sound-cloud-input input,
.tik-tok-input input,
.youtube-input input,
.instagram-input input {
  width: 243px;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 30px;
  padding-top: 3px;
  font-size: 16px;
}

.website-agreement-input {
  display: flex;
  align-items: center;
}

.website-input {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.website-input label {
  margin-bottom: 7px;
  font-family: "proximaNovaBold", sans-serif;
  font-size: 14px;
}

.website-input input {
  width: 243px;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 11px;
}

.agreement-input {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 25px;
  margin-top: 27px;
}

.agreement-1,
.agreement-2 {
  display: flex;
  flex-direction: row;
}

.agreement-1 p,
.agreement-2 p {
  font-size: 12px;
}

.agreement-1 p {
  margin-bottom: 7px;
}

.agreement-2 p {
  margin-bottom: 0px;
}

.checkbox-1,
.checkbox-2 {
  margin-top: 1px;
  margin-right: 10px;
  width: 14px;
  border: 1px solid #ed1b50;
  background-color: transparent;
  height: 14px;
}

.checkbox-1-checked,
.checkbox-2-checked {
  margin-top: 1px;
  margin-right: 10px;
  width: 14px;
  border: 1px solid #ed1b50;
  background-color: #ed1b50;
  height: 14px;
}

.next-button-complete {
  margin-top: 32px;
  background-color: #ed1b50;
}

.next-button-incomplete {
  margin-top: 32px;
  background-color: #b13b59;
}

.input-placeholder {
  color: rgba(27, 25, 77, 0.5);
  position: absolute;
  top: 60%;
  left: 5%;
}

/* multi-select containers */
.css-2b097c-container,
.css-yk16xz-control,
.css-1pahdxg-control,
.css-cj7im2-control {
  width: 243px !important;
  height: 30px !important;
  min-height: 30px !important;
  outline: none !important;
  border: none !important;
}

/* selected genre box */
.css-1rhbuit-multiValue {
  width: 40px !important;
  height: 20px !important;
  margin-bottom: 5px !important;
  border-radius: 5px !important;
  flex-shrink: 0 !important;
  background-color: #ed1b50 !important;
}
.css-1rhbuit-multiValue div {
  color: white;
}

/* inside multi-select container */
.css-g1d714-ValueContainer {
  flex-wrap: nowrap !important;
  overflow: scroll !important;
}

.css-4ljt47-MenuList div {
  color: black !important;
}

.css-b8ldur-Input {
  margin: 0 !important;
}
