.sign-up-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 1000px;
  justify-content: space-between;
  top: 152px;
  background: rgba(27, 25, 77, 0.65);
  border-radius: 10px;
  align-items: center;
}

.member-type-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 94px 36px 94px;
}

.password-rules-text {
  font-size: 12px;
  font-family: "signInFont", sans-serif;
  margin-top: 8px;
  color: rgba(244, 243, 255, 0.65);
  text-align: left;
}

.member-type-container h1 {
  font-size: 22px;
  margin-bottom: 27px;
  font-family: "titleFont", sans-serif;
}
.member-type-container p {
  margin-bottom: 27px;
  font-size: 12px;
}
.account-type-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.online-booking-agent-selector,
.artist-selector {
  width: 122px;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #ed1650;
  cursor: pointer;
}

.half-border {
  height: 424px;
  width: 1px;
  background-color: rgba(244, 243, 255, 0.05);
}

.online-booking-agent-selector {
  margin-right: 41px;
}

.online-booking-agent-selector:hover,
.online-booking-agent-selector:active {
  color: rgba(27, 25, 77, 1);
  background-color: #ed1650;
}

.online-booking-agent-selector2 {
  width: 122px;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #ed1650;
  cursor: pointer;
  margin-right: 41px;
  background-color: #ed1650;
  color: rgba(27, 25, 77, 1);
}

.artist-selector2 {
  width: 122px;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #ed1650;
  cursor: pointer;
  background-color: #ed1650;
  color: rgba(27, 25, 77, 1);
}

.artist-selector:hover {
  color: rgba(27, 25, 77, 1);
  background-color: #ed1650;
}

.online-booking-agent-selector p,
.online-booking-agent-selector2 p {
  font-size: 14px;
  font-family: "signInFont", sans-serif;
  margin: 0px;
  text-align: center;
}

.artist-selector p,
.artist-selector2 p {
  font-size: 14px;
  font-family: "signInFont", sans-serif;
  margin: 0px;
  text-align: center;
}
.sign-up-form {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 36px 94px 36px 94px;
  align-items: flex-start;
  /* text-align: left; */
}

.sign-up-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.sign-up-text h3 {
  font-size: 16px;
  letter-spacing: 2%;
}

.sign-up-form label {
  font-family: "signInFont", sans-serif;
  font-size: 13px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 4px;
}

.sign-up-form input {
  height: 35px;
  width: 100%;
  background-color: #f4f3ff;
  border-radius: 6px;
  border: none;
  margin-bottom: 18px;
  padding-left: 10px;
  padding-top: 2px;
}

.sign-up-password-wrong {
  background: url("../Visuals/SignUpConfirmationAssets/icon.png") no-repeat;
  background-position: 310px;
  background-size: 20px;
  padding-right: 30px;
}

.sign-up-password-correct {
  background: url("../Visuals/SignUpConfirmationAssets/circle-checked.png")
    no-repeat;
  background-position: 310px;
  background-size: 20px;
  padding-right: 30px;
}

.sign-up-password-default {
  background: none;
}

.sign-up-form input:focus {
  background-color: #f4f3ff;
  outline: none;
}

.create-account-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 35px;
  font-size: 13px;
  font-weight: 700;

  color: white;
  border: none;
  outline: none;
  background-color: #ed1650;
  font-family: "titleFont", sans-serif;
  letter-spacing: 5%;
}

.divider {
  margin-top: 1.5em;
  position: relative;
  font-size: 10px;
  margin-bottom: 0;
  text-transform: none;
}

.divider::before {
  content: "";
  display: block;
  width: 45%;
  height: 1px;
  position: absolute;
  background: #fff;
  left: 0;
  top: 40%;
}

.divider::after {
  content: "";
  display: block;
  width: 45%;
  height: 1px;
  position: absolute;
  background: #fff;
  right: 0;
  top: 40%;
}

.facebook-login-btn {
  margin-top: 1em;
  margin-bottom: 1.5em;
  height: 35px;
  width: 100%;
  background-color: #425b95;
  border: none;
  outline: none;
}

.facebook-login-btn span {
  padding-left: 10px;
  color: #fff;
  font-size: 13px;
}

.fa-facebook-f {
  color: white;
}

.sign-in-account h3 {
  font-size: 12px;
  font-family: "signInFont-2", sans-serif;
  text-transform: none;
}

.sign-in-account a {
  text-decoration: underline;
  color: white;
}

.sign-in-account a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.account-exists-text {
  color: red;
}

@media (max-width: 600px) {
  .sign-up-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 1000px;
    padding: 30px;
    justify-content: space-evenly;
    background: rgba(27, 25, 77, 0.65);
    border-radius: 10px;
    top: 0;
    align-items: center;
  }
  .sign-up-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .sign-up-text {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-bottom: 30px;
  }
  /* label {
    text-align: left;
  } */

  .sign-up-form input {
    height: 35px;
    position: relative;
    width: 75vw;
    background-color: #f4f3ff;
    border-radius: 6px;
    border: none;
    margin-bottom: 8%;
    padding-left: 10px;
    padding-top: 2px;
  }
  .half-border {
    display: none;
  }

  .create-account-btn {
    width: 75vw;
  }
  .password-rules-text {
    font-size: 12px;
    font-family: "signInFont", sans-serif;
    padding-top: 5%;
    color: rgba(244, 243, 255, 0.65);
    width: 50%;
  }
  .password-text {
    position: absolute;
    top: 150px;
    left: 190px;
    margin-bottom: 35px;
  }
  .email-text {
    position: absolute;
    top: 80px;
    right: 430px;
  }
}
