.admin-bank-info{
    background-color: var(--bg-blue);
    border: 1px solid var(--red-color);
    border-radius: 5px;
    padding: 30px 30px 20px 30px;
    /* top, right, bottom, left*/
    margin-bottom: 20px;
    width: 600px;
}

.filter-block{
    display: flex;
    padding: 0 15px;
    margin: 20px 0 30px;

}
.filter-block div{
    margin-right: 20px;
    width: 25%;
}
.filter-block label{
    color: #fff;
    font-size: 13px;
    display: block;
}

.filter-block .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
