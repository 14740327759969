@media screen and (min-width: 601px) {
    .watch-show-input{
      width: 235px;
    }
  
    .watch-show-btn{
      width: 160px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .watch-show-input{
      width: 280px;
    }
  
    .watch-show-btn{
      width: 200px;
    }
  
    .watch-show{
      text-align: center;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

.watch-show-input{
    color: var(--red-color);
    background-color: var(--blue-color)56;
    border: 1px solid var(--red-color);
    margin-bottom: 15px;
    margin-right: 5px;
    padding: 10px 10px;
    text-align: left;
    text-decoration: none;
    font-size: 16px;
}

.watch-show-input:focus{
    outline-color: var(--blue-color)56;
}

.watch-show-btn{
    color: var(--blue-color);
    background-color: var(--red-color);
    border: none;
    padding: 10px 10px;
    font-family: "titleFont", serif;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all .3s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: "5%"
}

.watch-show-btn:hover{
    color: white;
    text-decoration: none;
    cursor: pointer;
}