.forgot-password-container {
	display: flex;
	justify-content: center;
}

.forgot-password-content {
	display: flex;
	justify-content: center;
	width: 479px;
	height: 479px;
	background: rgba(27, 25, 77, 0.65);
	border-radius: 10px;
	flex-direction: column;
	align-items: center;
}

.confirm-password-content {
	display: flex;
	justify-content: center;
	width: 479px;
	height: 620px;
	background: rgba(27, 25, 77, 0.65);
	border-radius: 10px;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
}

.forgot-password-form {
	display: flex;
	flex-direction: column;
	width: 315px;
	margin-bottom: 20px;
}

.forgot-password-title {
	font-family: 'titleFont', sans-serif;
	font-size: 16px;
	margin-top: 35px;
	margin-bottom: 20px;
	text-align: center;
}

.forgot-password-text {
	text-align: left;
	font-size: 15px;
	font-family: 'proximaNova', sans-serif;
	margin-bottom: 10px;
}

.forgot-password-label {
	text-align: left;
	margin-bottom: 3px;
	font-family: 'proximaNova', sans-serif;
	font-size: 14px;
}

.forgot-password-input {
	padding-top: 2px;
	height: 30px;
	border-radius: 4px;
	padding-left: 11px;
	border: none;
	font-size: 14px;
	margin-bottom: 20px;
	outline: none;
}

.change-password-input,
.confirm-password-input {
	padding-top: 2px;
	height: 30px;
	border-radius: 4px;
	padding-left: 11px;
	border: none;
	font-size: 14px;
	outline: none;
}

.confirm-password-input {
	margin-bottom: 20 px;
}

.forgot-passwword-btn {
	height: 34px;
	margin-top: 0;
	margin-bottom: 20px;
}

.forgot-password-confirm-btn {
	margin-top: 0px;
	padding: 10px;
	width: 315px;
	height: 34px;
	background-color: rgba(237, 22, 80, 1);
}

.forgot-password-unconfirm-btn {
	margin-top: 0px;
	padding: 10px;
	width: 315px;
	height: 34px;
	background-color: #b13b59;
}

.forgot-password-code-form {
	padding: 0;
	display: flex;
	width: 315px;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 15px;
}

.forgot-password-code {
	padding: 0;
	display: flex;
	flex-direction: row;
}

.forgot-password-code-form input {
	width: 40px;
	height: 40px;
	padding-left: 13px;
	margin-top: 15px;
}

.resend-code {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.resend-code-link {
	margin-left: 4px;
	text-decoration: underline;
	cursor: pointer;
	margin-bottom: 29px;
}

.code-submit-btn {
	margin-top: 0px;
	width: 315px;
	height: 34px;
	background-color: #ed1b50;
}

.password-rules-text {
	font-size: 11px;
	font-family: 'signInFont', sans-serif;
	margin-top: 2px;
	color: rgba(244, 243, 255, 0.65);
	text-align: left;
}