.side-bar-container {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 12%;
  /* width: 12%; */
}

.side-bar-content {
  background: linear-gradient(180deg, #101035 39.58%, #ce3450 100%);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.side-bar-item {
  padding-left: 35px;
}

.agent-greeting {
  margin-top: 20px;
  margin-bottom: 54px;
}

.agent-greeting p {
  margin: 0;
  font-family: "titleFont", sans-serif;
  font-size: 18px;
}

.teder-logo img {
  width: 83px;
  height: 83px;
}
.teder-logo {
  display: flex;
  justify-content: center;
}

.home-btn,
.artists-btn,
.income-btn,
.settings-btn {
  display: flex;
  flex-direction: row;
  margin-bottom: 70px;
  cursor: pointer;
}

.home-btn img,
.artists-btn img,
.income-btn img,
.settings-btn img {
  width: 20px;
  height: 18px;
  margin-right: 15px;
  margin-top: 2px;
}
