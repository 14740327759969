@font-face {
  font-family: bodyFont;
  /* font-style: normal; */
  src: url("./fonts/ITCAvantGardeStdMd.otf") format("woff");
}
@font-face {
  font-family: titleFont;
  /* font-style: normal; */
  font-weight: bold;
  src: url("./fonts/ITCAvantGardeStdBold.otf") format("woff");
}

@font-face {
  font-family: signInFont;
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/ITCAvantGardeStdDemi.woff);
}

@font-face {
  font-family: signInFont-2;
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/ITCAvantGardeStdMd.otf);
}

@font-face {
  font-family: proximaNova;
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/ProximaNovaFont.otf);
}

@font-face {
  font-family: proximaNova2;
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/ProximaNova-Regular.otf);
}

@font-face {
  font-family: proximaNovaBold;
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/Proxima\ Nova\ Bold.otf);
}
